import { createStore } from 'vuex'
// 暂不启用
export default createStore({
  state: {
    user: {}
  },
  getters: {
    getUser() {
      return this.user
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    }
  },
  actions: {
  },
  modules: {
  }
})
