import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from 'axios'
import {VueMasonryPlugin} from 'vue-masonry';

axios.interceptors.request.use(config => {//声明请求拦截器
    // console.log(config);
    config.headers.Authorization = window.sessionStorage.getItem('TOKEN')//获取token值
    return config//一定要返回
})

const app = createApp(App)
app.use(store)
app.use(ElementPlus)
app.use(router)
app.use(VueMasonryPlugin)
app.config.globalProperties.axios = axios
app.mount('#app')
